<template>
	<div class="main-content">
		<ktv-breadcrumb title="Contract Lender List" page="Contract" folder="Loan" />
		<div :hidden="!mainGrid">
			<ktv-table :columns="columns" :rows="rows" @on-open-filter="openFilter()">
				<template #rows="{ props }">
					<span v-if="props.column.field == 'button'">
						<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
							<template #button-content>
								<span><i class="nav-icon i-Arrow-Down-in-Circle" /></span>
							</template>
							<b-dropdown-item href="#">
								<a v-b-tooltip.hover href="#" title="Edit" @click="openForm(props.row)">
									<i class="i-Pen-3 text-18 text-success mr-2" />
									{{ props.row.button }} Review Application Detail
								</a>
							</b-dropdown-item>
						</b-dropdown>
					</span>
				</template>
			</ktv-table>
		</div>
		<div :hidden="!mainForm">
			<b-row>
				<b-col md="12 mb-30">
					<b-card>
						<b-row>
							<b-col>
								<a v-b-tooltip.hover href="#" @click="backToList()">
									<i class="i-Arrow-Left-2 text-10" /><i class="i-Arrow-Left-2 text-10" />
									Back to List
								</a>
							</b-col>
							<b-col class="text-right">
								<div v-if="verification">
									<b-button variant="danger" class="mr-2" @click="rejectAction()"> Reject </b-button>
									<b-button variant="primary" @click="verifyAction()"> Approve </b-button>
								</div>
								<div v-else>
									<b-button pill variant="primary btn-dis-hover" disabled>
										<b>Approved</b>
									</b-button>
								</div>
							</b-col>
						</b-row>
						<form-wizard ref="wizard" title subtitle color="#54A7DC" step-size="xs" @on-complete="onComplete">
							<template #step="props">
								<wizard-step :key="props.tab.title" :tab="props.tab" :transition="props.transition" :index="props.index" />
							</template>
							<tab-content title="Upload Loan Document Contract" icon="i-Yes" />
							<tab-content title="Disbursement" icon="i-Yes" />
							<tab-content title="Repayment" icon="i-Yes" />
							<tab-content title="Done" icon="i-Yes" />
						</form-wizard>
						<div>
							<b-card border-variant="danger" header="Contract" header-bg-variant="primary" header-text-variant="white" align="left">
								<b-tabs active-nav-item-class="nav nav-tabs">
									<b-tab title="Application Detail" :active="activeTabDetail">
										<!-- Personal Data Section -->
										<b-card
											border-variant="primary"
											:header="$t('Personal Data', 'Personal Data')"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Name">
													<b-form-input v-model="form.Name" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Gender">
													<b-form-input v-model="form.Gender" type="text" readonly />
												</b-form-group>
												<b-form-group label="Birth Date" class="col-md-6">
													<b-form-input v-model="form.BirthDate" class="input" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Age">
													<b-form-input v-model="form.Age" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="ID Type">
													<b-form-input v-model="form.IDType" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="ID Number">
													<b-form-input v-model="form.IDNumber" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Family Section -->
										<b-card
											border-variant="primary"
											header="Family"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" :label="$t('Family Name', 'Family Name')">
													<b-form-input v-model="form.WifeName" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" :label="$t('Family ID Number', 'Family ID Number')">
													<b-form-input v-model="form.WifeIDNumber" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Certification Section -->
										<b-card
											border-variant="primary"
											header="Certification"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Status">
													<b-form-input v-model="form.StatusCert" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Type">
													<b-form-input v-model="form.TypeCert" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Address Section -->
										<b-card
											border-variant="primary"
											header="Address"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Province">
													<b-form-input v-model="form.Province" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="District">
													<b-form-input v-model="form.District" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Sub District">
													<b-form-input v-model="form.SubDistrict" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Village">
													<b-form-input v-model="form.Village" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12 mb-2" label="Address">
													<b-form-input v-model="form.Address" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Contact Section -->
										<b-card
											border-variant="primary"
											header="Contact"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Phone Number">
													<b-form-input v-model="form.PhoneNumber" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Email">
													<b-form-input v-model="form.Email" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Bank Account Section -->
										<b-card
											border-variant="primary"
											header="Bank Account"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Bank Name">
													<b-form-input v-model="form.BankName" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Bank Branch">
													<b-form-input v-model="form.BankBranch" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Name of Account Holder">
													<b-form-input v-model="form.AccountHolderName" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Acocunt Number">
													<b-form-input v-model="form.AccountNumber" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Business Data Section -->
										<b-card
											border-variant="primary"
											header="Business Data"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6" label="Business Type">
													<b-form-input v-model="form.BusinessType" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6" label="Business Established">
													<b-form-input v-model="form.BusinessEst" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Business List Section -->
										<b-card
											border-variant="primary"
											header="Business List"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<ktv-table :columns="columnsBusinessList" :line-numbers="true" :rows="form.rowsBusinessList" />
										<div style="height: 1.25rem" />

										<!-- Selling Transaction Section -->
										<b-card
											border-variant="primary"
											header="Selling Transaction Last 6 Months"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Repayment Amount (kg)">
													<b-form-input v-model="form.repaymentAmount" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Number of Transaction">
													<b-form-input v-model="form.NumberOfTransaction" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Document Data -->
										<b-row>
											<b-col cols="6">
												<b-card
													border-variant="primary"
													header="Identity Card"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													no-body
													class="mb-2"
												/>
												<div>
													<b-img
														center
														src="https://4.bp.blogspot.com/-1u7bmiMkN04/VigzfeXZFCI/AAAAAAAAADA/o3Yd3uwdmvY/s1600/KTP-600x416.jpg"
														v-bind="propsImage"
														class="mb-2"
													/>
												</div>
												<b-form>
													<b-row>
														<b-form-group class="col-md-8" label="ID Number" style="padding-bottom: 0" />
														<b-input-group class="col-md-12 mb-2">
															<b-form-input v-model="form.IdentityNumber" type="text" readonly />
															<b-input-group-append>
																<b-button variant="primary"> Download </b-button>
															</b-input-group-append>
														</b-input-group>
													</b-row>
												</b-form>
											</b-col>
											<b-col cols="6">
												<b-card
													border-variant="primary"
													header="Profile Photo"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													no-body
													class="mb-2"
												/>
												<div>
													<b-img
														center
														src="https://www.fastpay.co.id/blog/wp-content/uploads/2019/02/petani.jpg"
														v-bind="propsImage"
														class="mb-2"
													/>
												</div>
												<div class="text-right">
													<b-button variant="primary" size="sm" class="mt-3"> Download </b-button>
												</div>
											</b-col>
											<b-col cols="6" class="mt-3">
												<b-card
													border-variant="primary"
													header="Family Card"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													no-body
													class="mb-2"
												/>
												<div>
													<b-img
														center
														src="https://image.cermati.com/q_70/zdcr9hzn2q8y2p0f744f.webp"
														v-bind="propsImage"
														class="mb-2"
													/>
												</div>
												<b-form>
													<b-row>
														<b-form-group class="col-md-8" label="Family Card Number" style="padding-bottom: 0" />
														<b-input-group class="col-md-12 mb-2">
															<b-form-input v-model="form.FamilyCardNumber" type="text" readonly />
															<b-input-group-append>
																<b-button variant="primary"> Download </b-button>
															</b-input-group-append>
														</b-input-group>
													</b-row>
												</b-form>
											</b-col>
										</b-row>
										<div style="height: 1.25rem" />

										<!-- Loan Packet Section -->
										<b-card
											border-variant="primary"
											header="Loan Data"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Loan ID">
													<b-form-input v-model="form.LoanID" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Apply Date">
													<b-form-input v-model="form.ApplyDate" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Amount">
													<b-form-input v-model="form.AmountLoan" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Margin">
													<b-form-input v-model="form.Margin" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Provisi">
													<b-form-input v-model="form.Provisi" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Total Repayment">
													<b-form-input v-model="form.TotalRepayment" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Tenor">
													<b-form-input v-model="form.Tenor" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Due Date">
													<b-form-input v-model="form.DueDate" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Number of Payment">
													<b-form-input v-model="form.NumberOfPayment" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Loan Packet Section -->
										<b-card
											border-variant="primary"
											header="Loan Package"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Loan Package">
													<b-form-input v-model="form.LoanPackage" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Cash">
													<b-form-input v-model="form.CashLoan" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Number of Agri Inputs">
													<b-form-input v-model="form.ProductDashboard.length" type="text" readonly />
												</b-form-group>
											</b-row>
											<b-row>
												<b-col cols="4">
													<b-img
														center
														src="https://i2.wp.com/gdm.id/wp-content/uploads/2019/03/PUPUK-organik-GDM.png"
														v-bind="propsImageProduct"
													/>
												</b-col>
												<b-col cols="8">
													<b-form>
														<b-form-group class="col-md-12 mb-2" label="Agri Inputs Name" label-for="input-1">
															<b-form-input v-model="form.ProductDashboard.AgriinputName" type="text" readonly />
														</b-form-group>
														<b-form-group class="col-md-12 mb-2" label="Repayment Amount" label-for="input-1">
															<b-form-input v-model="form.ProductDashboard.AmountAgriinput" type="text" readonly />
														</b-form-group>
													</b-form>
												</b-col>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Supplier Section -->
										<b-card
											border-variant="primary"
											header="Retailer/Supplier"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Name">
													<b-form-input v-model="form.SupplierName" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Address">
													<b-form-input v-model="form.SupplierAddress" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
									</b-tab>
									<b-tab title="Credit Score" :active="activeTabCredit">
										<b-row>
											<b-col>
												<b-card
													border-variant="primary"
													header="Loan Application Detail"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													class="mb-2"
												>
													<table class="tablecustom-noborder">
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Loan ID</td>
															<td class="tablecustom-column-right">
																<b>LN0001</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Applicant</td>
															<td class="tablecustom-column-right">
																<b>Sahrul Ari</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Loan Type</td>
															<td class="tablecustom-column-right">
																<b>Farmer</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Repayment Amount</td>
															<td class="tablecustom-column-right">
																<b>Rp. 1.200.000,00</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Tenor</td>
															<td class="tablecustom-column-right">
																<b>6 months</b>
															</td>
														</tr>
													</table>
												</b-card>
											</b-col>
											<b-col>
												<b-card
													border-variant="primary"
													header="Certification"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													class="mb-2"
												>
													<table class="tablecustom-noborder">
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Certification ID</td>
															<td class="tablecustom-column-right">
																<b>CR0001</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Status</td>
															<td class="tablecustom-column-right">
																<b>Active</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Certifier</td>
															<td class="tablecustom-column-right">
																<b>MARS</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Certificate Standard</td>
															<td class="tablecustom-column-right">
																<b>RA</b>
															</td>
														</tr>
														<tr class="tablecustom-tr-space">
															<td class="tablecustom-column-left">Valid Date</td>
															<td class="tablecustom-column-right">
																<b>11 January 2025</b>
															</td>
														</tr>
													</table>
												</b-card>
											</b-col>
										</b-row>
										<div style="height: 1.25rem" />

										<!-- Business Data Section -->
										<b-card
											border-variant="primary"
											header="Business Data"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6" label="Business Type">
													<b-form-input v-model="form.BusinessType" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6" label="Business Established">
													<b-form-input v-model="form.BusinessEst" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Business List Section -->
										<b-card
											border-variant="primary"
											header="Business List"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<ktv-table :columns="columnsBusinessList" :line-numbers="true" :rows="form.rowsBusinessList" />
										<div style="height: 1.25rem" />

										<!-- Selling Transaction Section -->
										<b-card
											border-variant="primary"
											header="Selling Transaction Last 6 Months"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Repayment Amount (kg)">
													<b-form-input v-model="form.repaymentAmount" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Number of Transaction">
													<b-form-input v-model="form.NumberOfTransaction" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<!-- Applicant Profile Section -->
										<b-card
											border-variant="primary"
											header="Applicant Profile"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Cow per Family">
													<b-form-input v-model="credit.CowPerFam" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Age">
													<b-form-input v-model="credit.Age" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Education">
													<b-form-input v-model="credit.Education" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<b-row>
											<b-col>
												<!-- Ownership & Collateral Section -->
												<b-card
													border-variant="primary"
													header="Ownership & Collateral"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													no-body
													class="mb-2"
												/>
												<b-form>
													<b-row>
														<b-form-group class="col-md-12 mb-2" label="Liquidation Value" label-for="input-1">
															<b-form-input v-model="credit.Liquidation" type="text" readonly />
														</b-form-group>
													</b-row>
												</b-form>
												<div style="height: 1.25rem" />
											</b-col>
											<b-col>
												<!-- Financial Capacity Section -->
												<b-card
													border-variant="primary"
													header="Financial Capacity"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													no-body
													class="mb-2"
												/>
												<b-form>
													<b-row>
														<b-form-group class="col-md-12 mb-2" label="Affordability" label-for="input-1">
															<b-form-input v-model="credit.Affordability" type="text" readonly />
														</b-form-group>
													</b-row>
												</b-form>
												<div style="height: 1.25rem" />
											</b-col>
										</b-row>

										<!-- Business Profile Section -->
										<b-card
											border-variant="primary"
											header="Business Profile"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Years of Experience">
													<b-form-input v-model="credit.YearsExperience" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="GAP">
													<b-form-input v-model="credit.Gap" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />

										<div class="box-finalscore">
											<b-row align-v="center">
												<b-col>
													<b class="text-35">Final Score</b>
												</b-col>
												<b-col>
													<b-row class="text-center">
														<b-col>
															<b class="text-25 text-primary">C</b>
															<br />
															Grade
														</b-col>
														<b-col>
															<b class="text-25 text-primary">65</b>
															<br />
															Total Score
														</b-col>
														<b-col>
															<b class="text-25">140</b>
															<br />
															Maximum Score
														</b-col>
													</b-row>
												</b-col>
											</b-row>
										</div>
									</b-tab>
									<b-tab title="Loan Contract" :active="activeTabContract">
										<b-card
											border-variant="primary"
											header="Upload Loan Contract"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<div v-if="divUploaded" style="width: 50%">
												<b-button variant="primary" @click="pickfile"> Browse </b-button>
												&nbsp; No file choosen.
												<b-form-file id="upload" v-model="fileLoan" style="display: none" @change="pickedfile" />
												<div class="text-grey mt-2">
													<i>***Upload document in PDF Format (max 10 MB)</i>
												</div>
											</div>
											<div v-else-if="!divUploaded" class="boxuploaded">
												<b-row align-v="center">
													<b-col cols="10">
														<b-button variant="outline-secondary" class="btnicon" disabled @click="pickfile">
															<i class="i-File-Horizontal-Text text-30" />
														</b-button>
														&nbsp;
														{{ fileLoan.name }}
														<!-- &nbsp;&nbsp; -->
													</b-col>
													<b-col cols="2" class="text-right">
														<b-button v-b-tooltip.hover class="close mr-2" title="delete" @click="deleteFileLoan">
															<i class="i-Close text-20 text-red" />
														</b-button>
													</b-col>
												</b-row>
											</div>
										</b-form>
									</b-tab>
									<b-tab title="Disbursement" :active="activeTabDisburse">
										<b-row>
											<b-col>
												<b-card
													border-variant="primary"
													header="Disburse to Retailer"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													class="mb-2"
												>
													<b-form>
														<b-row>
															<b-form-group class="col-md-6 mb-2" label="Contract ID">
																<b-form-input v-model="disburse.contractId" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Loan Package">
																<b-form-input v-model="disburse.loanPacket" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Amount">
																<b-form-input v-model="disburse.amount" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Retailer Name">
																<b-form-input v-model="disburse.retailerName" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-12 mb-2" label="Payment Method">
																<b-form-select v-model="disburse.paymentMethod" :options="options.payMethod" />
															</b-form-group>
															<b-form-group class="col-md-12 mb-2 mt-2 text-right" label="">
																<b-button variant="primary" @click="openDisburse('toRetailer')"> Disburse </b-button>
															</b-form-group>
														</b-row>
													</b-form>
												</b-card>
											</b-col>
											<b-col>
												<b-card
													border-variant="primary"
													header="Disburse to Farmer"
													header-bg-variant="primary"
													header-text-variant="white"
													align="left"
													class="mb-2"
												>
													<b-form>
														<b-row>
															<b-form-group class="col-md-6 mb-2" label="Contract ID">
																<b-form-input v-model="disburse.contractId" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Loan Package">
																<b-form-input v-model="disburse.loanPacket" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Amount">
																<b-form-input v-model="disburse.amount" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-6 mb-2" label="Farmer Name">
																<b-form-input v-model="disburse.farmerName" type="text" readonly />
															</b-form-group>
															<b-form-group class="col-md-12 mb-2" label="Payment Method">
																<b-form-select v-model="disburse.paymentMethod" :options="options.payMethod" />
															</b-form-group>
															<b-form-group class="col-md-12 mb-2 mt-2 text-right" label="">
																<b-button variant="primary"> Disburse </b-button>
															</b-form-group>
														</b-row>
													</b-form>
												</b-card>
											</b-col>
										</b-row>
										<div style="height: 1.25rem" />
									</b-tab>
									<b-tab title="Repayment" :active="activeTabRepayment">
										<b-button variant="success" @click="addRepay()"> Add Applicant Repayment </b-button>
										<div style="height: 1.25rem" />
										<b-card
											border-variant="primary"
											header="Repayment"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<b-form>
											<b-row>
												<b-form-group class="col-md-6 mb-2" label="Ampunt of Repayment">
													<b-form-input v-model="repay.amountRepayment" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Repayment has been Paid">
													<b-form-input v-model="repay.amountPaid" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Tenor">
													<b-form-input v-model="repay.tenor" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-6 mb-2" label="Terms of Payment">
													<b-form-input v-model="repay.termsOfPayment" type="text" readonly />
												</b-form-group>
											</b-row>
										</b-form>
										<div style="height: 1.25rem" />
										<b-card
											border-variant="primary"
											header="Repayment History"
											header-bg-variant="primary"
											header-text-variant="white"
											align="left"
											no-body
											class="mb-2"
										/>
										<ktv-table :columns="columnsRepayList" :line-numbers="true" :rows="form.rowsRepayList" />
										<div style="height: 1.25rem" />
									</b-tab>
								</b-tabs>
							</b-card>
						</div>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<ktv-modal v-model="modalsFilter" title="Filter" no-close-on-backdrop no-close-on-esc @close="modalsFilter = false">
			<template #content>
				<b-form-group class="col-md-12 mb-2" label="Category">
					<b-form-select v-model="filter.Category" :options="options.category" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Lender">
					<b-form-select v-model="filter.Lender" :options="options.lender" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Status">
					<b-form-select v-model="filter.Status" :options="options.status" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Date">
					<b-form-input v-model="filter.Date" type="date" />
				</b-form-group>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="modalsFilter = false"> Apply Filter </b-button>
				<div style="margin-right: 30px" />
				<b-button variant="light" size="sm" class="float-right" style="margin-right: 5px" @click="modalsFilter = false"> Reset </b-button>
			</template>
		</ktv-modal>
		<ktv-modal v-model="modalsDisburse" size="lg" title="Disburse" no-close-on-backdrop no-close-on-esc @close="modalsDisburse = false">
			<template #content>
				<b-col>
					<b-list-group>
						<b-list-group-item>
							<b-row align-v="center">
								<b-col class="text-18">
									<b>Payment via BRIVA</b>
								</b-col>
								<b-col align="right">
									<img :src="require('@/assets/images/BRILogo.png')" style="width: 80px; height: 70px" />
								</b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							Transaction ID <br />
							<b>TRX873476500</b>
						</b-list-group-item>
						<b-list-group-item>
							<b-row align-v="center">
								<b-col>
									Virtual Account No <br />
									<b>0029387693221</b>
								</b-col>
								<b-col class="text-20 text-primary text-right"> Copy </b-col>
							</b-row>
						</b-list-group-item>
						<b-list-group-item>
							Destination <br />
							<b>Sugata Farm Retail</b>
						</b-list-group-item>
						<b-list-group-item>
							<b-row align-v="center">
								<b-col>
									Transaction Amount <br />
									<b>Rp. 1.200.000</b>
								</b-col>
								<b-col class="text-20 text-primary text-right"> Copy </b-col>
							</b-row>
						</b-list-group-item>
					</b-list-group>
				</b-col>
				<b-col>
					<b-list-group>
						<b-list-group-item class="text-18">
							<b>Payment Instruction</b>
						</b-list-group-item>
						<b-list-group-item class="text-18">
							<b-row>
								<b-col cols="8" class="text-18">
									<b>ATM BRI</b>
								</b-col>
								<b-col cols="4" align="right">
									<i v-b-toggle.accordion-1 class="i-Arrow-Down" />
									<!-- <b-button block  variant="info"></b-button> -->
								</b-col>
							</b-row>
							<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel" class="text-12">
								1. Pada ATM, pilih <b>Transaksi Lain</b>, kemudian pilih <b>Pembayaran</b>. <br />
								2. Kemudian pilih <b>Lainnya</b>, lalu pilih <b>BRIVA</b>. <br />
								3. Masukkan <b>nomor BRIVA</b> dan <b>nominal pembayaran</b>. <br />
								4. Konfirmasi jumlah pembayaran dan selesaikan pembayaran.
							</b-collapse>
						</b-list-group-item>
						<b-list-group-item class="text-18">
							<b-row>
								<b-col cols="8" class="text-18">
									<b>Internet Banking BRI</b>
								</b-col>
								<b-col cols="4" align="right">
									<i v-b-toggle.accordion-2 class="i-Arrow-Down" />
								</b-col>
							</b-row>
							<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>tets</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-list-group-item>
						<b-list-group-item class="text-18">
							<b-row>
								<b-col cols="8" class="text-18">
									<b>Mobile Banking BRI</b>
								</b-col>
								<b-col cols="4" align="right">
									<i v-b-toggle.accordion-3 class="i-Arrow-Down" />
								</b-col>
							</b-row>
							<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-card-text>tets</b-card-text>
								</b-card-body>
							</b-collapse>
						</b-list-group-item>
					</b-list-group>
				</b-col>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="modalsDisburse = false"> Done </b-button>
			</template>
		</ktv-modal>
		<ktv-modal v-model="modalsRepay" title="Add Repayment" no-close-on-backdrop no-close-on-esc @close="modalsRepay = false">
			<template #content>
				<b-form-group class="col-md-12 mb-2" label="Amount">
					<b-form-input v-model="repayAdd.amountRepayment" type="text" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Payment Receipt">
					<b-form-file v-model="repayAdd.fileReceipt" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Date">
					<b-form-input v-model="repayAdd.datePayment" type="text" />
				</b-form-group>
				<b-form-group class="col-md-12 mb-2" label="Payment Method">
					<b-form-input v-model="repayAdd.paymentMethod" type="text" />
				</b-form-group>
			</template>
			<template #footer>
				<b-button variant="primary" size="sm" class="float-right" @click="modalsRepay = false"> Submit </b-button>
			</template>
		</ktv-modal>
	</div>
</template>

<script>
	import { KtvModal, KtvTable } from "@/components"

	export default {
		metaInfo: {
			title: "Contract Loan",
		},
		components: { KtvModal, KtvTable },
		data() {
			return {
				divUploaded: true,
				mainGrid: true,
				mainForm: false,
				modalsFilter: false,
				propsImage: { width: 300, height: 200 },
				propsImageProduct: { width: 200, height: 130 },
				verification: true,
				activeTabDetail: false,
				activeTabCredit: false,
				activeTabContract: true,
				activeTabDisburse: false,
				activeTabRepayment: false,
				btnUpld: true,
				fileLoan: null,
				modalsDisburse: false,
				modalsRepay: false,
				columnsBusinessList: [
					{
						label: "Business",
						field: "BusName",
						thClass: "text-left",
					},
					{
						label: "Status",
						field: "BusStatus",
						thClass: "text-left",
					},
					{
						label: "Size",
						field: "BusSize",
						thClass: "text-left",
					},
					{
						label: "Production",
						field: "BusProduction",
						thClass: "text-left",
					},
					{
						label: "Lat",
						field: "BusLat",
						thClass: "text-left",
					},
					{
						label: "Long",
						field: "BusLong",
						thClass: "text-left",
					},
					{
						label: "Action",
						field: "ButtonAct",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
						sortable: false,
					},
				],
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
					{
						label: "Contract ID",
						field: "contractId",
						thClass: "text-left",
					},
					{
						label: "Contract Status",
						field: "contractStatus",
						thClass: "text-left",
					},
					{
						label: "Name",
						field: "nameLoan",
						thClass: "text-left",
					},
					{
						label: "Category",
						field: "categoryName",
						thClass: "text-left",
					},
					{
						label: "Lender",
						field: "lender",
						thClass: "text-left",
					},
					{
						label: "Repayment Amount",
						field: "repaymentAmount",
						thClass: "text-left",
					},
					{
						label: "Tenor",
						field: "tenor",
						thClass: "text-left",
					},
					{
						label: "Status",
						field: "statusLoan",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
					},
				],
				rows: [
					{
						contractId: 1122793,
						contractStatus: "active",
						tenor: "2021-10-11",
						nameLoan: "Andin",
						categoryName: "Farmer",
						lender: "Bank Jaya",
						repaymentAmount: "Rp. 20.000.000",
						statusLoan: "Waiting Contract Doc",
					},
					{
						contractId: 8273911,
						contractStatus: "active",
						tenor: "2021-12-10",
						nameLoan: "Raka",
						categoryName: "SME",
						lender: "Koperasi Bunda",
						repaymentAmount: "Rp. 14.000.000",
						statusLoan: "Waiting Contract Doc",
					},
					{
						contractId: 237281,
						contractStatus: "done",
						tenor: "2021-08-07",
						nameLoan: "Tina",
						categoryName: "Farmer",
						lender: "Bank Aji",
						repaymentAmount: "Rp. 8.000.000",
						statusLoan: "Waiting Disbursement",
					},
				],
				columnsRepayList: [
					{
						label: "Amount",
						field: "amount",
						thClass: "text-left",
					},
					{
						label: "Due Date",
						field: "dueDate",
						thClass: "text-left",
					},
					{
						label: "Payment Method",
						field: "paymentMethod",
						thClass: "text-left",
					},
					{
						label: "Status",
						field: "status",
						thClass: "text-center",
					},
				],
				rowsRepayList: [],
				form: {
					Name: "",
					BirthDate: "",
					IDType: "",
					Gender: "",
					Age: "",
					IDNumber: "",
					WifeName: "",
					WifeIDNumber: "",
					StatusCert: "",
					TypeCert: "",
					Province: "",
					District: "",
					SubDistrict: "",
					Village: "",
					Address: "",
					PhoneNumber: "",
					Email: "",
					BankName: "",
					BankBranch: "",
					AccountHolderName: "",
					AccountNumber: "",
					BusinessType: "",
					BusinessEst: "",
					rowsBusinessList: [
						// {
						//     BusName: '',
						//     BusStatus: '',
						//     BusSize: '',
						//     BusProduction: '',
						//     BusLat: '',
						//     BusLong: ''
						// }
					],
					repaymentAmount: "",
					NumberOfTransaction: "",
					IdentityNumber: "",
					ProfilePhoto: "",
					FamilyCardNumber: "",
					contractId: "",
					ApplyDate: "",
					AmountLoan: "",
					Margin: "",
					Provisi: "",
					TotalRepayment: "",
					Tenor: "",
					LoanPackage: "",
					CashLoan: "",
					NumberOfAgriinput: "",
					ProductDashboard: [
						{
							AgriinputName: "",
							AmountAgriinput: "",
						},
					],
					SupplierName: "",
					SupplierAddress: "",
				},
				credit: {
					CowPerFam: "",
					Age: "",
					Education: "",
					Liquidation: "",
					Affordability: "",
					YearsExperience: "",
					Gap: "",
				},
				repay: {
					amountRepayment: "",
					ampuntPaid: "",
					tenor: "",
					termsOfPayment: "",
				},
				repayAdd: {
					amount: "",
					datePayment: "",
					paymentMethod: "",
					fileReceipt: null,
				},
				disburse: {
					contractId: "",
					LoanPacket: "",
					amount: "",
					retailerName: "",
					farmerName: "",
					paymentMethod: "",
				},
				filter: {
					Lender: "",
					Category: "",
					Status: "",
					Date: "",
				},
				options: {
					category: [],
					lender: [],
					status: [],
					payMethod: [],
				},
			}
		},
		mounted() {
			this.getData()
		},
		methods: {
			addRepay() {
				this.modalsRepay = true
			},
			openDisburse() {
				this.modalsDisburse = true
			},
			deleteFileLoan() {
				this.divUploaded = true
				this.fileLoan = null
			},
			pickedfile() {
				this.divUploaded = false
			},
			pickfile() {
				document.getElementById("upload").click()
			},
			rejectAction() {
				this.$swal({
					title: this.$t("Why the application rejected?", "Why the application rejected?"),
					text: this.$t("Explain why the loan application form is rejected", "Explain why the loan application form is rejected"),
					input: "textarea",
					inputAttributes: {
						autocapitalize: "off",
					},
					allowOutsideClick: () => !this.$swal.isLoading(),
					customClass: {
						title: "swal-reject-title",
						content: "swal-reject-label",
						actions: "swal-reject-footer",
						confirmButton: "swal-reject-btn",
						cancelButton: "swal-reject-btn",
						footer: "swal-reject-footer",
					},
					showCancelButton: true,
					confirmButtonText: this.$t("Submit", "Submit"),
					showLoaderOnConfirm: true,
					preConfirm: (login) => {
						return fetch(`//api.github.com/users/${login}`)
							.then((response) => {
								if (!response.ok) {
									throw new Error(response.statusText)
								}
								return response.json()
							})
							.catch((error) => {
								this.$swal.showValidationMessage(`${this.$t("Request failed", "Request failed")}: ${error}`)
							})
					},
				}).then((result) => {
					if (result.isConfirmed) {
						this.$swal({
							title: `${result.value.login}'s avatar`,
							imageUrl: result.value.avatar_url,
						})
					}
				})
			},
			verifyAction() {
				this.$swal({
					type: "success",
					title: `${this.$t("Loan application verified", "Loan application verified")}!`,
					showConfirmButton: true,
					customClass: {
						title: "swal-reject-title",
						content: "swal-reject-label",
						actions: "swal-reject-footer",
						confirmButton: "swal-reject-btn",
						cancelButton: "swal-reject-btn",
						footer: "swal-reject-footer",
					},
				}).then(() => {
					this.verification = false
					this.activeTabDetail = false
					this.activeTabCredit = true
					this.$refs.wizard.nextTab()
				})
			},
			openFilter() {
				this.modalsFilter = true
			},
			nextStep() {
				this.$refs.wizard.nextTab()
			},
			onComplete: function () {
				this.$swal({
					position: "top-end",
					type: "success",
					title: this.$t("Your work has been saved", "Your work has been saved"),
					showConfirmButton: false,
					timer: 1500,
				})
			},
			backToList() {
				this.mainGrid = true
				this.mainForm = false
				this.verification = true
				this.activeTabDetail = true
				this.activeTabCredit = false
				this.divUploaded = true
				this.fileLoan = null
			},
			openForm() {
				this.mainForm = true
				this.mainGrid = false
				// this.$refs.wizard.changeTab(0,1);
				// this.$refs.wizard.navigateToTab(2);
				this.$refs.wizard.reset()
			},
			getData() {
				// this.$http
				//     .post('/access/login?userLoginId='+
				//     this.userLoginId+'&password='+
				//     this.password)
				//     .then((response) => {
				//         console.log(response);
				// })
				// this.$http
				//     .get('fooExample')
				//     .then((response) => {
				//         console.log(response);
				// })
			},
		},
	}
</script>

<style>
	.boxuploaded {
		border: 2px solid #54a7dc;
		width: 50%;
	}
	.btnicon {
		border: 0 !important;
		padding: 10px 0 10px 10px !important;
	}
	.disabled {
		pointer-events: none;
		opacity: 0.3;
	}
	.vue-form-wizard .wizard-tab-content {
		padding: 30px 0px 10px;
	}
	.wizard-tab-content {
		display: none;
	}
	.wizard-card-footer {
		display: none;
	}
	.swal-reject-label {
		font-size: 0.8em;
		text-align: left;
	}
	.swal-reject-title {
		font-size: 1.5rem;
		color: black;
	}
	.swal2-textarea:focus {
		border: 1px solid #54a7dc !important;
	}
	.swal-reject-footer {
		margin: 0;
	}
	.swal-reject-btn {
		padding: 0.5rem 1.25rem !important;
		font-size: 0.813rem !important;
	}
	.btn-dis-hover {
		pointer-events: none !important;
	}
</style>
